/* eslint-disable max-depth */
/* eslint-disable complexity */
import html2canvas from 'html2canvas'
import JsPDF from 'jspdf'
import { createDOM } from './dom'
import { getPromise } from './promise'
import { logError } from './bi/log'

export const rotateBase64Img = (src, edg, callback) => {
  try {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    let imgW = 0 // 图片宽度
    let imgH = 0 // 图片高度
    let size = 0 // canvas初始大小

    if (edg % 90 !== 0) {
      throw new Error('旋转角度必须是90的倍数!')
    }

    edg < 0 && (edg = (edg % 360) + 360)
    const quadrant = (edg / 90) % 4 // 旋转象限
    const cutCoor = { sx: 0, sy: 0, ex: 0, ey: 0 } // 裁剪坐标

    const image = new Image()
    image.src = src
    image.crossOrigin = 'anonymous'

    image.onload = function () {
      imgW = image.width
      imgH = image.height
      size = imgW > imgH ? imgW : imgH

      canvas.width = size * 2
      canvas.height = size * 2
      switch (quadrant) {
        case 0:
          cutCoor.sx = size
          cutCoor.sy = size
          cutCoor.ex = size + imgW
          cutCoor.ey = size + imgH
          break
        case 1:
          cutCoor.sx = size - imgH
          cutCoor.sy = size
          cutCoor.ex = size
          cutCoor.ey = size + imgW
          break
        case 2:
          cutCoor.sx = size - imgW
          cutCoor.sy = size - imgH
          cutCoor.ex = size
          cutCoor.ey = size
          break
        case 3:
          cutCoor.sx = size
          cutCoor.sy = size - imgW
          cutCoor.ex = size + imgH
          cutCoor.ey = size + imgW
          break
      }

      ctx.translate(size, size)
      ctx.rotate((edg * Math.PI) / 180)
      ctx.drawImage(image, 0, 0)

      if (typeof callback === 'function') {
        callback(canvas.toDataURL('image/png', 0.7))
      }
    }
  } catch (e) {
    logError(e)
  }
}

export const cropBase64Img = (base64, callback) => {
  const img = new Image()
  img.src = base64
  img.onload = function () {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    canvas.width = img.width
    canvas.height = img.height
    ctx.drawImage(img, 0, 0)
    const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height).data
    let lOffset = canvas.width
    let rOffset = 0
    let tOffset = canvas.height
    let bOffset = 0
    for (let i = 0; i < canvas.width; i++) {
      for (let j = 0; j < canvas.height; j++) {
        const pos = (i + canvas.width * j) * 4
        if (
          imgData[pos] === 255
          || imgData[pos + 1] === 255
          || imgData[pos + 2] === 255
          || imgData[pos + 3] === 255
        ) {
          bOffset = Math.max(j, bOffset) // 找到有色彩的最下端
          rOffset = Math.max(i, rOffset) // 找到有色彩的最右端
          tOffset = Math.min(j, tOffset) // 找到有色彩的最上端
          lOffset = Math.min(i, lOffset) // 找到有色彩的最左端
        }
      }
    }
    lOffset++
    rOffset++
    tOffset++
    bOffset++

    const output = document.createElement('canvas')
    output.width = rOffset - lOffset
    output.height = bOffset - tOffset
    output
      .getContext('2d')
      .drawImage(
        img,
        lOffset,
        tOffset,
        output.width,
        output.height,
        0,
        0,
        output.width,
        output.height,
      )
    if (typeof callback === 'function') {
      callback(output.toDataURL())
    }
  }
}

export function dataURLtoFile(dataUrl, name) {
  const { promise, resolve } = getPromise()
  const http = new XMLHttpRequest()
  http.open('GET', dataUrl, true)
  http.responseType = 'blob'
  http.onload = function () {
    if (this.status === 200 || this.status === 0) {
      resolve(
        new window.File([this.response], name, {
          type: 'image/png',
        }),
      )
    }
  }
  setTimeout(() => http.send(), 0)
  return promise
}

export function fileToBase64(file, callback) {
  const fileReader = new FileReader()
  fileReader.readAsDataURL(file)
  fileReader.onload = function () {
    callback(this.result)
  }
}

export function base64ToFile(base64, fileName, mimeType = null) {
  const arr = base64.split(',')
  const defaultMimeType = arr[0].match(/:(.*?);/)[1]
  const bStr = atob(arr[1])
  let n = bStr.length
  const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bStr.charCodeAt(n)
  }
  return new File([u8arr], fileName, { type: mimeType || defaultMimeType })
}

export function blobToFile(blob, fileName, mimeType) {
  return new File([blob], fileName, { type: mimeType })
}

/**
 * 图片压缩和尺寸裁剪
 * @param {File} file                       图片文件
 * @param {Object} options
 * @param {Number} options.quality          生成图片质量，0.0~1.0之间，质量越小、文件越小、图片越模糊
 * @param {Number} options.sizeThreshold    [可选]大小阈值，单位：B，默认500K
 * @param {Number} options.targetWidth      [可选]生成图片的宽度，单位：px，默认800
 * @param {Number} options.targetHeight     [可选]生成图片的高度，单位：px，默认值按宽度自适应获取
 */
export function pressImg(
  file,
  options = {
    quality: 0.92,
    sizeThreshold: 512000,
    targetWidth: 800,
    targetHeight: null,
  },
) {
  if (!file || !file.type.includes('image')) {
    return Promise.reject(new Error('file invalid'))
  }
  const { promise, resolve, reject } = getPromise()
  fileToBase64(file, (base64) => {
    if (base64) {
      const image = new Image()
      image.src = base64
      image.onload = function () {
        if (
          file.size <= options.sizeThreshold
          && this.width <= options.targetWidth
        ) {
          resolve(file)
          return
        }
        const canvas = document.createElement('canvas')
        const context = canvas.getContext('2d')
        const scale = this.width / this.height
        canvas.width = options.targetWidth
        canvas.height = options.targetHeight || options.targetWidth / scale
        context.drawImage(image, 0, 0, canvas.width, canvas.height)
        const dataURL = canvas.toDataURL(file.type, options.quality)
        resolve(base64ToFile(dataURL, file.name))
      }
      image.onerror = () => reject()
    }
  })
  return promise
}

export function html2Img(dom) {
  const view = createDOM(
    '<div'
      + ' class="html2canvas-wrapper"'
      + ' style="position:fixed;top:2000px;opacity:0"'
      + '></div>',
  )
  view.appendChild((dom = dom.cloneNode(true)))
  document.body.append(view)

  return html2getLink(dom, view).finally(() => {
    document.body.removeChild(view)
  })
}

export function html2getLink(dom, view) {
  const { promise, resolve, reject } = getPromise()
  let finished = 0
  const imgs = view ? view.querySelectorAll('img') : dom.querySelectorAll('img')
  imgs.forEach((item) => {
    let url = item.src
    if (!url.startsWith('data:image')) {
      if (!url.includes('?')) {
        url = `${url}?t=${Date.now()}`
      } else {
        url = `${url}&t=${Date.now()}`
      }
    }
    item.crossOrigin = 'anonymous'
    item.src = url

    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    item.onload = function () {
      canvas.width = item.naturalWidth
      canvas.height = item.naturalHeight
      ctx.drawImage(this, 0, 0, canvas.width, canvas.height)
      item.src = canvas.toDataURL('image/png', 1)
      item.onload = null
      if (++finished >= imgs.length) {
        // https://html2canvas.hertzen.com/configuration
        html2canvas(dom, {
          scale: 1,
          useCORS: true,
          backgroundColor: null,
        })
          .then((canvas) => {
            resolve(canvas.toDataURL())
          })
          .catch(e => reject(e))
      }
    }
    item.onerror = e => reject(e)
  })
  return promise
}

export function html2pdf({
  dom,
  title,
  pageDom,
  pdfRgb = [255, 255, 255],
  direction = '',
}) {
  const { promise, resolve, reject } = getPromise()
  const opts = {
    scale: 1,
    useCORS: true,
    backgroundColor: null,
  }
  if (!dom) {
    reject()
    return promise
  }
  const PDF = new JsPDF(direction, 'pt', 'a4')
  // eslint-disable-next-line max-params
  function addBlank(x, y, width, height) {
    PDF.setFillColor(...pdfRgb)
    PDF.rect(x, y, Math.ceil(width), Math.ceil(height), 'F')
  }
  const outerDomStyle = getComputedStyle(dom)
  const outerDomPaddingTop = parseFloat(outerDomStyle.paddingTop)
  const outerDomPaddingBottom = parseFloat(outerDomStyle.paddingBottom)
  const outerDomPadding = outerDomPaddingTop + outerDomPaddingBottom
  // TODO: 后续内部有图片需要另外处理
  html2canvas(dom, opts).then((canvas) => {
    const contentWidth = canvas.width
    const contentHeight = canvas.height
    const imgWidth = 595.28
    const pdfHeight = 841.89
    const imgHeight = (imgWidth / contentWidth) * contentHeight
    const pageData = canvas.toDataURL('image/jpeg', 1.0)
    // 一页pdf显示html页面生成的canvas高度;
    const pageHeight = (contentWidth / imgWidth) * pdfHeight
    // 未生成pdf的html页面高度
    let leftHeight = contentHeight
    let position = 0
    let curPageHeight = pageHeight
    if (pageDom) { // 仅适用于获取外层dom内的所有子节点
      const domList = dom.querySelectorAll(pageDom)
      const childDomArr = []
      for (let i = 0; i < domList.length; i++) {
        const item = domList[i]
        const style = getComputedStyle(item)
        const marginTop = parseFloat(style.marginTop)
        const marginBottom = parseFloat(style.marginBottom)
        const itemHeight = item.offsetHeight + marginTop + marginBottom
        const itemImgHeight = (imgWidth / contentWidth) * itemHeight
        childDomArr[i] = {
          imgHeight: itemImgHeight,
          width: item.offsetWidth,
          height: itemHeight,
        }
      }
      let domIdx = 0
      if (leftHeight < curPageHeight) {
        PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
        addBlank(0, Math.floor(imgHeight), imgWidth, pdfHeight)
      } else {
        // 超过一页时，分页打印
        PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
        leftHeight -= outerDomPadding
        curPageHeight -= outerDomPaddingTop
        position -= outerDomPaddingTop * (imgWidth / contentWidth)
        while (leftHeight > 0 && domIdx < domList.length) {
          const currHeight = childDomArr[domIdx].height
          const currImgHeight = childDomArr[domIdx].imgHeight
          if (currHeight <= curPageHeight) {
            leftHeight -= currHeight
            curPageHeight -= currHeight
            position -= currImgHeight
            domIdx++
          } else {
            const topHeight = (pageHeight - curPageHeight) * (imgWidth / contentWidth)
            addBlank(0, Math.floor(topHeight), imgWidth, imgHeight)
            PDF.addPage()
            curPageHeight = pageHeight
            let domLeftHeight = currHeight
            PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
            if (domLeftHeight <= curPageHeight) {
              curPageHeight -= currHeight
              position -= currImgHeight
              domIdx++
            } else { // 子节点超过一页时 分页打印
              while (domLeftHeight > 0) {
                domLeftHeight -= pageHeight
                if (domLeftHeight > 0) {
                  position -= pdfHeight
                  PDF.addPage()
                  PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
                } else {
                  domIdx++
                  curPageHeight = Math.abs(domLeftHeight)
                  position -= (pageHeight - curPageHeight) * ((imgWidth / contentWidth))
                }
              }
            }
            leftHeight -= currHeight
          }
          if (domIdx === domList.length) { // 最后一页底部剩余颜色填充
            const topHeight = (pageHeight - curPageHeight) * (imgWidth / contentWidth)
            addBlank(0, Math.floor(topHeight), imgWidth, imgHeight)
          }
        }
      }
    } else {
      if (leftHeight < curPageHeight) {
        PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
        addBlank(0, Math.floor(imgHeight), imgWidth, pdfHeight)
      } else {
        // 超过一页时，分页打印
        while (leftHeight > 0) {
          PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
          leftHeight -= pageHeight
          position -= pdfHeight
          if (leftHeight > 0) {
            PDF.addPage()
          }
        }
        const topHeight = (pageHeight - Math.abs(leftHeight)) * (imgWidth / contentWidth)
        addBlank(0, Math.floor(topHeight), imgWidth, imgHeight)
      }
    }
    resolve()
    PDF.save(`${title}.pdf`)
  }).catch(e => reject(e))
  return promise
}

export default html2canvas
